<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <validation-provider rules="required" v-slot="{ errors }" name="machinery">
            <div class="row-div">
              <div class="item-name-div">设备：</div>
              <div class="item-control-div">
                <label>
                  <select v-model="dataInfo.machineryId">
                    <option value="">请选择设备</option>
                    <option v-for="item in manufacturerTypes" :value="item.id">{{item.name}}</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <validation-provider rules="required|max:20" v-slot="{ errors }" name="title">
            <div class="row-div">
              <div class="item-name-div">标题：</div>
              <div class="item-control-div">
                <label><input type="text" v-model="dataInfo.name" placeholder="请输入标题"></label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <validation-provider rules="max:50" v-slot="{ errors }" name="subtitle">
            <div class="row-div">
              <div class="item-name-div">副标题：</div>
              <div class="item-control-div">
                <label><input type="text" v-model="dataInfo.subtitle" placeholder="请输入副标题"></label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">描述：</div>
            <div>
              <label>
                <textarea v-model="dataInfo.memo" placeholder="请输入描述"/>
              </label>
            </div>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave()">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { getManufacturerUsedTypes, postManufacturerAtlasAdd } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      manufacturerTypes: [],
      dataInfo: {
        machineryId: "",
        name: "",
        subtitle: "",
        memo: ""
      },
      isShowAddImg: false,
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getManufacturerUsedTypes()
        .then(data => {
          this.manufacturerTypes = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询设备类型失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.dataInfo.memo.length > 200){
        this.setTipsMsg("备注最多允许输入200个字符");
        return;
      }

      this.$refs.form.validate().then(res => {
        if(res){
          postManufacturerAtlasAdd(this.dataInfo)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      })
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 500px;
    margin: 20px auto 0 auto;
    .item-div {
      margin-top: 5px;
      .row-div {
        display: flex;
        align-items: center;
        .item-name-div {
          width: 60px;
          text-align: right;
          margin-right: 15px;
        }
      }
      .tips-div {
        width: calc(100% - 75px);
        height: 25px;
        margin-left: 80px;
      }
      input {
        width: 358px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
      }
      select {
        width: 220px;
        height: 33px;
        border: 1px solid #dcdcdc;
      }
      textarea {
        resize: none;
        width: 362px;
        height: 62px;
        border: 1px solid #dcdcdc;
        padding: 3px;
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>