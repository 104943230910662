<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <div class="type-msg-div">
        <validation-observer ref="form">
          <div class="item-div">
            <div class="row-div device-div">
              <div class="item-name-div">设备：</div>
              <div class="item-control-div">{{ dataInfo.machineryName }}</div>
            </div>
          </div>
          <div class="item-div">
            <validation-provider rules="required|max:20" v-slot="{ errors }" name="title">
              <div class="row-div">
                <div class="item-name-div">标题：</div>
                <div class="item-control-div">
                  <label><input type="text" v-model="dataInfo.name" placeholder="请输入标题"></label>
                </div>
              </div>
              <div class="tips-div err-tips">{{ errors[0] }}</div>
            </validation-provider>
          </div>
          <div class="item-div">
            <validation-provider rules="max:50" v-slot="{ errors }" name="subtitle">
              <div class="row-div">
                <div class="item-name-div">副标题：</div>
                <div class="item-control-div">
                  <label><input type="text" v-model="dataInfo.subtitle" placeholder="请输入副标题"></label>
                </div>
              </div>
              <div class="tips-div err-tips">{{ errors[0] }}</div>
            </validation-provider>
          </div>
          <div class="item-div">
            <div class="row-div">
              <div class="item-name-div">描述：</div>
              <div>
                <label>
                  <textarea v-model="dataInfo.memo" maxlength="200" placeholder="请输入描述"/>
                </label>
              </div>
            </div>
          </div>
          <div class="operate-div">
            <input type="button" value="保存" @click="onSave()">
          </div>
        </validation-observer>
      </div>
      <div class="atlas-img-div">
        <div v-for="(item, k) in dataInfo.atlas" class="atlas-item-div">
          <div class="atlas-operate-div">
            <label>位置：<input type="text" v-model="item.index" maxlength="2" placeholder="请输入位置"></label>
            <input type="button" value="保存" @click="onSaveImg(k)">
            <input type="button" value="删除" @click="onDelImg(k)">
          </div>
          <div class="preview-img-div">
            <img v-if="Object.prototype.hasOwnProperty.call(item, 'id')" :src="`${$imgBaseURL}/${item.img}`" alt="" @click="onShowBigImgDiv($event)">
            <img v-else src="" :src="item.img" alt="" @click="onShowBigImgDiv($event)">
          </div>
        </div>
        <div class="select-img-div">
          <label class="upload-img-label" @change="onAddImg($event)">
            <img class="cursor-el" src="../../assets/picture.png" alt="" />
            <input class="cursor-el" type="file" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getManufacturerAtlasDetails, postManufacturerAtlasEdit, postManufacturerAtlasImgAdd, postManufacturerAtlasImgEdit, postManufacturerAtlasImgDel } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    typeId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dataInfo: {},
      newImg: [],
      isShowBigImg: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getManufacturerAtlasDetails({typeId: this.typeId})
        .then(data => {
          if(!Object.prototype.hasOwnProperty.call(data, "atlas")){
            data.atlas = [];
          }

          this.dataInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询图解信息失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onSave() {
      this.$refs.form.validate().then(res => {
        if(res){
          postManufacturerAtlasEdit(this.dataInfo)
            .then(() => {
              this.refreshData();
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      })
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.dataInfo.atlas.push({
          file: img,
          img: reader.result,
          index: 99
        })
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onSaveImg(index) {
      if(Object.prototype.hasOwnProperty.call(this.dataInfo.atlas[index], "id")){
        postManufacturerAtlasImgEdit({id: this.dataInfo.atlas[index].id, index: this.dataInfo.atlas[index].index})
          .then(() => {
            this.initData();
            this.$layer.msg("编辑成功");
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      }else{
        let formData = new FormData();
        formData.append("typeId", this.typeId)
        formData.append("index", this.dataInfo.atlas[index].index)
        formData.append("file", this.dataInfo.atlas[index].file)
        postManufacturerAtlasImgAdd(formData)
          .then(() => {
            this.initData();
            this.$layer.msg("添加成功");
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      }
    },
    onDelImg(index) {
      if(Object.prototype.hasOwnProperty.call(this.dataInfo.atlas[index], "id")){
        postManufacturerAtlasImgDel({id: this.dataInfo.atlas[index].id, typeId: this.typeId})
          .then(() => {
            this.initData();
            this.$layer.msg("删除成功");
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      }else{
        this.dataInfo.atlas.splice(index, 1);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 530px;
    margin: 20px auto 0 auto;
    .type-msg-div {
      border: 1px solid #dee0e2;
      border-radius: 5px;
      .operate-div {
        text-align: right;
        margin-top: 10px;
        input {
          width: 120px;
          height: 35px;
          color: white;
          font-size: 16px;
          border: none;
          background: #4676c8;
          margin: 5px 10px 10px 0;
        }
      }
    }
    .atlas-img-div {
      border: 1px solid #dee0e2;
      border-radius: 5px;
      margin-top: 10px;
      .atlas-item-div {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dee0e2;
        padding: 5px 0;
        .atlas-operate-div {
          line-height: 35px;
          margin-left: 5px;
          input[type="text"] {
            width: 60px;
            height: 23px;
            padding: 0 5px;
            border: 1px solid #dcdcdc;
          }
          input[type="button"] {
            margin-left: 5px;
          }
        }
        .preview-img-div {
          width: 120px;
          height: 120px;
          margin-left: 20px;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .select-img-div {
        display: flex;
        justify-content: flex-end;
      }
    }
    .item-div {
      margin-top: 5px;
      .row-div {
        display: flex;
        align-items: center;
        .item-name-div {
          width: 60px;
          text-align: right;
          margin-right: 15px;
        }
      }
      .device-div {
        margin-bottom: 15px;
      }
      .tips-div {
        width: calc(100% - 75px);
        height: 25px;
        margin-left: 80px;
      }
      input {
        width: 358px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
      }
      select {
        width: 220px;
        height: 33px;
        border: 1px solid #dcdcdc;
      }
      textarea {
        resize: none;
        width: 362px;
        height: 62px;
        border: 1px solid #dcdcdc;
        padding: 3px;
      }
    }
  }
}
</style>